<template>
  <div class="single-traffic-flow manage-scroll">
    <div class="flex space-between res-page">
      <div class="res-left">
        <div class="res-canvas">
          <canvas-car></canvas-car>
        </div>
        <div class="res-bridge">
          <chart3D ref="chart3D" :value="chartData13" loading="loading13"></chart3D>
        </div>
      </div>
      <div class="res-right">
        <div class="res-video">
          <div class="h5videowrapper h5container" v-if="videoList[0]">
            <vue-aliplayer-v2
                ref="videoPlayer0"
                :source="videoList[0].hls"
                :options="options"
                @ready="handleReady(0)"
            />
          </div>
        </div>
        <div class="res-video">
          <div class="h5videowrapper h5container" v-if="videoList[1]">
            <vue-aliplayer-v2
                ref="videoPlayer1"
                :source="videoList[1].hls"
                :options="options"
                @ready="handleReady(1)"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import CanvasCar from '../../../components/manage/canvasCar'
  import chart3D from "../../structure/response/charts/chart3D.vue"
  import VueAliplayerV2 from "vue-aliplayer-v2";
  export default {
    components:{ CanvasCar,chart3D,VueAliplayerV2 },
    data(){
      return{
        loading13:false,
        chartData13:{
          title:'桥梁模型',
          data:{}
        },
        timer:null,
        isFirst:0,
        options: {
          height: "100%",
          isLive: true,
          format: "m3u8",
          autoplay: true,
          skinLayout: false
        },
        videoList:[]
      }
    },
    created() {
      this.getVideo()
      this.timer = setInterval(() => {
        this.getData13()
      },3000)
      // this.getData13()

    },
    methods:{
      getVideo(){
        this.$http.get('/video/channel/road').then(res => {
          if (res.success){
            if (res.data){
              this.videoList = res.data
            }
          }
        })
      },
      handleReady(index) {
        this.$refs['videoPlayer' + index].play();
      },
      //桥模型数据
      getData13(){
        // this.loading13 = true
        this.$http.get("/echart/data/wholeBridge").then(res => {
          if(res.success){
            if (res.data){
              this.isFirst++
              if (this.isFirst === 1){
                this.$refs['chart3D'].loadEcharts(res.data)
              } else {
                this.$refs['chart3D'].changeData(res.data)
              }
            }
          } else {
            this.$message.error(res.msg)
          }
          // this.loading13 = false
        })
      },
    },
    beforeDestroy() {
      clearInterval(this.timer);
      this.timer = null
    }
  }
</script>

<style scoped>
  .res-page {
    width: 100%;
    /*height: 100%;*/
  }
  .res-left{
    width: 62%;
    height: 500px;
  }
  .res-canvas{
    width: 100%;
    height: 500px;
    margin-bottom: 20px;
  }
  .res-bridge{
    width: 100%;
    height: 500px;
    /*height: calc(100% - 520px);*/
    background: #fff;
  }
  .res-right{
    width: 37%;
    /*height: 500px;*/
  }
  .res-video{
    width: 100%;
    height: 500px;
    margin-bottom: 20px;
    background: #333;
  }
  .h5videowrapper{
    width: 100%;
    height: 100%;
  }
  /*.res-video:last-child{*/
  /*  width: 100%;*/
  /*  height: calc(100% - 520px);*/
  /*}*/
</style>