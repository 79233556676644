<template>
  <div class="car-canvas" :class="'qm_img' + carlines">
    <canvas id="canvas">
      你的浏览器不支持canvas，请更换其他浏览器试一试
    </canvas>
  </div>
</template>

<script>
  import Stomp from "stompjs";
  export default {
    name: "canvasCar",
    data(){
      return{
        client:null,
        topic:'',
        speed:0,
        casH:0,
        casW:0,
        bodyheight:0,
        bodywidth:0,
        cvs_context:null,
        loadingCarCount: true,
        // qm_img:6,
        carlines:6,
        carWeight:'',
        carImgType: [
          {id: 0, h: 180, w: 90},
          {id: 1, h: 180, w: 90},
          {id: 2, h: 140, w: 70},
          {id: 3, h: 140, w: 70},
          {id: 4, h: 140, w: 70},
          {id: 5, h: 160, w: 70},
          {id: 6, h: 180, w: 70}
        ],
      }
    },
    created() {
      let p1 = this.getTopic()
      let p2 = this.getCarLaneNum()
      Promise.all([p1,p2]).then(() => {
        this.startListen();
      }, () => {});
    },
    mounted() {
      this.initCanvas();
    },
    methods:{
      getCarLaneNum(){
        return new Promise((resolve)=>{
          this.$http.get('/car/getCarLaneNum').then(res => {
            if (res.success){
              this.carlines = res.data
              // this.startListen()
            }
            return resolve();
          })
        }).catch(()=>{})
      },
      getTopic(){
        return new Promise((resolve)=>{
          this.$http.get('/car/getCarTopicName').then(res => {
            if (res.success){
              this.topic = res.data
              // this.startListen()
            }
            return resolve();
          })
        }).catch(()=>{})
      },
      initCanvas(){
        let that = this;
        let canvas=document.getElementById('canvas');
        that.casH = document.getElementsByClassName('car-canvas')[0].clientHeight
        that.casW = document.getElementsByClassName('car-canvas')[0].clientWidth
        canvas.height=that.casH;
        canvas.width=that.casW;
        let context=canvas.getContext('2d');
        that.cvs_context = context;
        // var img = new Image();
        // img.src="../../../static/img/car/car_l.png";
        // img.src="https://t12.baidu.com/it/u=861288555,1591836781&fm=58";
        // console.log(img);
        // context.drawImage(img,150,150);
        (function frame() {
          window.requestAnimationFrame(frame);
          that.cvs_context.clearRect(0,0,that.casW,that.casH);
        })();
      },
      getDayCar(){
        this.chartData.loading = true;
        let date = this.$moment(new Date()).format('YYYY-MM-DD')
        this.$http.get('/car/statistics/day',{params:{day:date}}).then(res => {
          if(res.success){
            if (res.data){
              this.chartData.series[0].data = res.data.yaxis;
              this.chartData.xdata = res.data.xaxis;
            }
          } else {
            this.$message.error(res.msg)
          }
          this.chartData.loading = false;
        })
      },
      //开启监听mq
      startListen(){
        var timestamp = new Date().getTime();
        var clientid = timestamp + Math.floor(Math.random() * 10000);
        var headers = {
          login: this.$MQ_USER,
          passcode: this.$MQ_PWD,
          "client-id": clientid
        };
        if (!this.client || !this.client.connected) {
          this.client = Stomp.client(this.$MQURL);
        }
        this.client.connect(
            headers,
            this.onConnected,
            this.onFailed
        );
      },
      onConnected: function(frame) {
        var topic = "/topic/" + this.topic;
        // 订阅频道
        this.client.subscribe(topic, this.responseCallback, this.onFailed);
        this.client.debug = null;
      },
      onFailed: function(frame) {
        console.log("Failed: " + frame);
      },
      responseCallback: function(frame) {
        let body = JSON.parse(frame.body);
        let lanenum = body.lane_num;
        let carnum = body.car_num;
        let grossweight = body.gross_weight;
        let speed = body.speed;
        // var img = body.picNum;
        // var isweight = body.exp1;
        let carType = body.car_type;
        this.addcar(lanenum,carnum,grossweight,speed,carType);
      },
      disconnect: function() {
        if(this.client){
          let that = this
          that.client.disconnect(function(e) {
            that.client = null
          });
        }
      },
      addcar(lanenum,carnum,grossweight,speed,carType){
        // console.log(carType);
        carType = carType.toString()
        let type = ["2", "3", "4", "5", "6"].includes(carType.substring(0, 1))?carType.substring(0, 1):"2";
        var that = this;
        // 此处可添加不同车型要加载图标的样式，根据数据中车辆的类型赋予不同车型的图片。
        var img = new Image();
        // img.src="../../../static/img/car/car_l.png";
        img.src=require("../../../../assets/images/car/l"+type+".png");
        var img1 = new Image();
        // img1.src="../../../static/img/car/car_r.png";
        img1.src=require("../../../../assets/images/car/r"+type+".png");
        /* 根据cavas固定高度、固定宽度，计算第一车道绘制车辆图标的起始位置y_start，然后根据车道数计算多增一个车道的偏移。
        * 然后通过绘制图片的方法动态绘制。 */
        var x = that.casW;
        var h_rated = document.getElementsByClassName('car-canvas')[0].clientHeight;//canvas额定高度。
        var _carlines = that.carlines;
        var linenum = parseInt(lanenum);
        var y_start = (h_rated/_carlines-80)/2;//计算初始纵轴偏移值
        var y_step = h_rated/_carlines;//计算偏移量
        if(linenum<(_carlines/2+1)){//判断车辆行驶方向，根据车辆所在车道是否小于车道的一半
          (function frame() {
            window.requestAnimationFrame(frame);
            that.drawCar(x,y_start+y_step*(linenum-1),img,lanenum,carnum,grossweight,speed,carType,1);
            x -= 1;
          })();
        }else{
          x=0;
          (function frame() {
            window.requestAnimationFrame(frame);
            that.drawCar(x,y_start+y_step*(linenum-1),img1,lanenum,carnum,grossweight,speed,carType,0);
            x += 1;
          })();
        }
      },
      drawCar(x,y,img1,lanenum,carnum,grossweight,speed,carType,dest){//绘制小车的方法
        const that = this;
        let type = carType.substring(0, 1);
        that.cvs_context.beginPath();
        that.cvs_context.drawImage(img1,x,y,that.carImgType[type].h,that.carImgType[type].w);
        that.cvs_context.font="16px Arial";
        that.cvs_context.fillStyle="#FFA500";
        that.cvs_context.fillText(carnum,x+40+dest*20,y+40);
        // that.cvs_context.fillText(grossweight+"kg",x+50+dest*20,y+45);
        // that.cvs_context.fillText(speed+"km/h",x+50+dest*20,y+60);
        that.cvs_context.closePath();
      },
      changeImg(lanenum,img){//更改超重车辆的图片，暂时不用
        const that = this;
        var date = new Date();
        var year = date.getFullYear();
        var month = date.getMonth() + 1;
        var day = date.getDate();
        var imgurl = "/carimg/"+year+"_"+month+"_"+day+"/"+img+".jpg";//拼接图片路径。
        switch(parseInt(lanenum)){
          case 1:
            that.img1 = imgurl;
            break;
          case 2:
            that.img2 = imgurl;
            break;
          case 3:
            that.img3 = imgurl;
            break;
          case 4:
            that.img4 = imgurl;
            break;
        }
      }
    },
    beforeDestroy() {
      this.disconnect();
    }
  }
</script>

<style scoped>
  .car-canvas{
    width: 100%;
    height: 100%;
    background-size: 100% 100%;
  }
  .qm_img2{
    background-image: url(../../../../assets/images/car/qm2.png);
  }
  .qm_img4{
    background-image: url(../../../../assets/images/car/qm4.png);
  }
  .qm_img6{
    background-image: url(../../../../assets/images/car/qm6.png);
  }
</style>